import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.getElementById("picture-0").classList.remove("hidden")
  }

  getActivePicId() {
    return Array.from(document.querySelectorAll('.item-show-slider-picture')).filter((picture) => (!picture.classList.contains('hidden')))[0].id.match(/\d/)[0]
  }

  prev() {
    const picsCount = document.querySelectorAll('.item-show-slider-picture').length
    const id = Number.parseInt(this.getActivePicId())

    document.getElementById(`picture-${id}`).classList.add("hidden")

    if (id === 0) {
      document.getElementById(`picture-${picsCount - 1}`).classList.remove("hidden")
    } else {
      document.getElementById(`picture-${id - 1}`).classList.remove("hidden")
    }
  }

  next() {
    const picsCount = document.querySelectorAll('.item-show-slider-picture').length
    const id = Number.parseInt(this.getActivePicId())

    document.getElementById(`picture-${id}`).classList.add("hidden")

    if (id + 1 === picsCount) {
      document.getElementById(`picture-0`).classList.remove("hidden")
    } else {
      document.getElementById(`picture-${id + 1}`).classList.remove("hidden")
    }
  }
}
