import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    if (document.getElementById("params-install-times")?.innerText.length > 0) {
      JSON.parse(document.getElementById("params-install-times").innerText).forEach((i) => {
        if (i.length === 0) {
          return
        }
        document.getElementById(`filter_installation_times_${i}`).checked = true
      })
    }
    if (document.getElementById("params-categories")?.innerText.length > 0){
      JSON.parse(document.getElementById("params-categories").innerText).forEach((i) => {
        if (i.length === 0) {
          return
        }
        document.getElementById(`filter_categories_${i.toLowerCase().replace(/ /g, '_')}`).checked = true
      })
    }
    if (document.getElementById("params-publics")?.innerText.length > 0){
      JSON.parse(document.getElementById("params-publics").innerText).forEach((i) => {
        if (i.length === 0) {
          return
        }
        document.getElementById(`filter_public_${i.toLowerCase().replace(/ /g, '_')}`).checked = true
      })
    }
    if (document.getElementById("params-types")?.innerText.length > 0){
      JSON.parse(document.getElementById("params-types").innerText).forEach((i) => {
        if (i.length === 0) {
          return
        }
        document.getElementById(`filter_tutorial_types_${i.toLowerCase().replace(/ /g, '_').replace(/\'/g, '')}`).checked = true
      })
    }
  }
  
  selectDifficulty(e) {
    document.getElementById(e.target.id.replace('trigger', 'filter_installation_times')).checked = !document.getElementById(e.target.id.replace('trigger', 'filter_installation_times')).checked
    e.target.classList.toggle("selected")
  }

  selectCategory(e) {
    let target = e.target
    while (target.classList.contains("toggle-triggers-lines") === false) {
      target = target.parentNode
    }

    document.getElementById(target.id.replace("trigger", "filter_categories")).checked = !document.getElementById(target.id.replace("trigger", "filter_categories")).checked
    document.querySelector(`#${target.id} .fa-toggle-off`).classList.toggle('hidden')
    document.querySelector(`#${target.id} .fa-toggle-on`).classList.toggle('hidden')
  }

  selectPublic(e) {
    let target = e.target
    while (target.classList.contains("toggle-triggers-lines") === false) {
      target = target.parentNode
    }

    document.getElementById(target.id.replace("trigger", "filter_public")).checked = !document.getElementById(target.id.replace("trigger", "filter_public")).checked
    document.querySelector(`#${target.id} .fa-toggle-off`).classList.toggle('hidden')
    document.querySelector(`#${target.id} .fa-toggle-on`).classList.toggle('hidden')
  }

  selectType(e) {
    let target = e.target
    while (target.classList.contains("toggle-triggers-lines") === false) {
      target = target.parentNode
    }

    document.getElementById(target.id.replace("trigger", "filter_tutorial_types")).checked = !document.getElementById(target.id.replace("trigger", "filter_tutorial_types")).checked
    document.querySelector(`#${target.id} .fa-toggle-off`).classList.toggle('hidden')
    document.querySelector(`#${target.id} .fa-toggle-on`).classList.toggle('hidden')
  }
}
