import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import { fetchGet } from '../plugins/api'

export default class extends Controller {
  static targets = [
    'rangepicker',
    'datepicker',
    'endDate',
    'helpDates',
    'quantity',
    'quantityBlock',
  ]

  static values = {
    stockDispo: String,
  }

  async initFlatpickr(unavailableUrl) {
    console.log(unavailableUrl)
    // const rangeInput = document.querySelector('#range')
    console.log(unavailableUrl)
    const { unavailable_dates: unavailableDates } = JSON.parse(
      await fetchGet(unavailableUrl)
    )

    console.log('unavailableDates', unavailableDates)

    if (this.rangepickerTarget) {
      const options = {
        altInput: true,
        altFormat: 'd-m-Y',
        disable: [
          function (date) {
            const isoDate = new Date(
              date.getTime() - date.getTimezoneOffset() * 60000
            )
              .toISOString()
              .split('T')[0]
            return (
              unavailableDates.includes(isoDate) ||
              date > new Date().setFullYear(new Date().getFullYear() + 1) ||
              date < new Date()
            )
          },
        ],
        inline: true,
        locale: French,
        mode: 'range',
        minDate: 'today',
        maxDate: new Date().setFullYear(new Date().getFullYear() + 1),
        time_24hr: true,
      }

      flatpickr(this.rangepickerTarget, options)
    }
  }
  async rangeSelect() {
    if (this.rangepickerTarget.value) {
      const dates = this.rangepickerTarget.value.split(' au ')
      this.datepickerTarget.value = dates[0]
      if (dates.length === 1) {
        this.endDateTarget.value = dates[0]
      } else {
        this.endDateTarget.value = dates[1]
      }

      const { stock_disp: stockDisp } = JSON.parse(
        await fetchGet(
          `${this.stockDispoValue}?start_date=${this.datepickerTarget.value}&end_date=${this.endDateTarget.value}`
        )
      )

      console.log(stockDisp)

      this.quantityTarget.disabled = false
      this.quantityBlockTarget.classList.remove('hidden')
      this.quantityTarget.max = stockDisp
      this.quantityTarget.value = 1
      this.helpDatesTarget.innerText = `max: ${stockDisp}`

      //     .then((response) => response.json())
      //     .then((data) => {
      //       const stockDisp = parseInt(data.stock_disp)
      //       quantityInput.disabled = false
      //       document.getElementById('quantity-block').classList.remove('hidden')
      //       quantityInput.max = stockDisp
      //       quantityInput.value = 1
      //       helpDates.innerText = `max: ${stockDisp}`
    }
  }
}
