import Flatpickr from './flatpickr_controller'

export default class extends Flatpickr {
  static targets = ['submitButton']
  static values = {
    stockDispo: String,
    unavailableUrl: String,
  }

  async connect() {
    await this.initFlatpickr(this.unavailableUrlValue)
  }

  onRangeChanged() {
    this.submitButtonTarget.removeAttribute('disabled')
    this.rangeSelect()
  }
}
