import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.getElementById("picture-0").classList.remove("hidden")

    this.data.set('intervalId', setInterval(() => {
      this.next()
    }, 3000))
  }

  disconnect() {
    clearInterval(this.data.get('intervalId'))
  }

  getActivePicId() {
    return Array.from(document.querySelectorAll('.slider-picture')).filter((picture) => (!picture.classList.contains('hidden')))[0].id.match(/\d/)[0]
  }

  next() {
    const picsCount = document.querySelectorAll('.slider-picture').length
    const id = Number.parseInt(this.getActivePicId())

    document.getElementById(`picture-${id}`).classList.add("hidden")

    if (id + 1 === picsCount) {
      document.getElementById(`picture-0`).classList.remove("hidden")
    } else {
      document.getElementById(`picture-${id + 1}`).classList.remove("hidden")
    }
  }

  closeAnnouncement() {
    document.querySelector('.announcement').classList.add("hidden")
  }
}
