import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navbar"];

  connect() {
    this.handleScroll();
  }

  openMenu() {
    document.getElementById("open-mobile-menu").classList.add("hidden")
    document.getElementById("close-mobile-menu").classList.remove("hidden")
    document.querySelector(".mobile-menu-links").classList.remove("hidden")
    document.querySelector(".logo-full").classList.add("menu-opened-logo")
  }

  closeMenu() {
    document.getElementById("open-mobile-menu").classList.remove("hidden")
    document.getElementById("close-mobile-menu").classList.add("hidden")
    document.querySelector(".mobile-menu-links").classList.add("hidden")
    document.querySelector(".logo-full").classList.remove("menu-opened-logo")
  }

  handleScroll() {
    const y = window.scrollY;
    const className = this.navbarTarget.classList[0];

    if (y > 150 && className !== "small") {
      this.navbarTarget.classList.add("small");
      this.navbarTarget.classList.remove("large");
    } else if (y <= 80) {
      this.navbarTarget.classList.remove("small");
      this.navbarTarget.classList.add("large");
    }
  }
}
