import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { initFlatpickr } from '../plugins/flatpickr'

export default class extends Controller {
  static targets = [
    'archiveAllButton',
    'archiveAllForm',
    'archiveAllCheckbox',
    'messageSlot',
    'editModal',
    'datesDisplay',
    'stockDisplay',
    'mainActions',
    'stockPicker',
    'datesPickers',
    'stockNotice',
    'editActions',
  ]

  getStockData(id, w) {
    const stockIds = ['stockDynamic', 'availableStock', 'increase', 'decrease']
    return stockIds.map((i) => id.replace(w, i))
  }

  toggle() {
    ;[
      this.datesDisplayTarget,
      this.stocksDisplayTarget,
      this.mainActionsTarget,
      this.stockPickerTarget,
      this.datesPickersTarget,
      this.stockDisplayTarget,
      this.editActionsTarget,
    ].forEach((target) => {
      if (target !== null && target !== undefined)
        target.classList.toggle('hidden')
    })
  }

  confirmEdit(e) {
    const id = e.target.id
    const bookingsIds = document.getElementById(
      id.replace('icon', 'ids')
    ).innerText
    const oldStartDate = document.getElementById(
      id.replace('confirm-icon', 'oldStartDate')
    ).innerText
    const oldEndDate = document.getElementById(
      id.replace('confirm-icon', 'oldEndDate')
    ).innerText
    const newStartDate = document.getElementById(
      id.replace('confirm-icon', 'newStartDate')
    ).value
    const newEndDate = document.getElementById(
      id.replace('confirm-icon', 'newEndDate')
    ).value
    const oldStock = document.getElementById(
      id.replace('confirm-icon', 'oldStock')
    ).innerText
    const newStock = document.getElementById(
      id.replace('confirm-icon', 'stockDynamic')
    ).innerText

    let params = {}

    if (newStartDate !== undefined && newStartDate !== oldStartDate) {
      params.start_date = newStartDate
    }

    if (newEndDate !== undefined && newEndDate !== oldEndDate) {
      params.end_date = newEndDate
    }

    if (newStock !== undefined && newStock !== oldStock) {
      params.stock = newStock
    }

    console.log(params)

    if (Object.keys(params).length > 0) {
      Rails.ajax({
        type: 'GET',
        url: `/admin/bookings/edit-json?ids=${bookingsIds}&${Object.keys(params)
          .map((k) => `${k}=${params[k]}`)
          .join('&')}`,
        dataType: 'json',
        success: ({ message }) => {
          const messageSlot = document.getElementById('message-slot')
          if (message === 'success') {
            console.log('success')
            messageSlot.innerHTML = '<span>La réservation a été modifiée</span>'
            messageSlot.classList.add('success-message')
          } else {
            console.log('error')
            messageSlot.innerHTML = '<span>Vos modifications ont échoué</span>'
            messageSlot.classList.add('error-message')
          }

          messageSlot.classList.remove('hidden')
          setTimeout(() => {
            messageSlot.classList.add('hidden')
          }, 3000)
        },
      })
    }
  }

  increaseStock(e) {
    console.log(e.target.id)
    const [currentStock, availableStock, increaseButton, decreaseButton] =
      this.getStockData(e.target.id, 'increase')
    console.log(
      [currentStock, availableStock],
      [currentStock, availableStock].map((i) => document.getElementById(i))
    )
    const [currentStockNbr, availableStockNbr] = [
      currentStock,
      availableStock,
    ].map((i) => Number.parseInt(document.getElementById(i).innerText))
    const [
      currentStockTarget,
      availableStockTarget,
      increaseButtonTarget,
      decreaseButtonTarget,
    ] = [currentStock, availableStock, increaseButton, decreaseButton].map(
      (i) => document.getElementById(i)
    )

    if (availableStockNbr > 0) {
      currentStockTarget.innerText = (currentStockNbr + 1).toString()
      availableStockTarget.innerText = (availableStockNbr - 1).toString()
      decreaseButtonTarget.classList.remove('disabled')
    }

    if (currentStockNbr + 1 > availableStockNbr) {
      increaseButtonTarget.classList.add('disabled')
    }
  }

  decreaseStock(e) {
    const [currentStock, availableStock, increaseButton, decreaseButton] =
      this.getStockData(e.target.id, 'decrease')
    const [currentStockNbr, availableStockNbr] = [
      currentStock,
      availableStock,
    ].map((i) => Number.parseInt(document.getElementById(i).innerText))
    const [
      currentStockTarget,
      availableStockTarget,
      increaseButtonTarget,
      decreaseButtonTarget,
    ] = [currentStock, availableStock, increaseButton, decreaseButton].map(
      (i) => document.getElementById(i)
    )

    if (currentStockNbr > 0) {
      currentStockTarget.innerText = (currentStockNbr - 1).toString()
      availableStockTarget.innerText = (availableStockNbr + 1).toString()
      increaseButtonTarget.classList.remove('disabled')
    }

    if (currentStockNbr - 1 === 0) {
      decreaseButtonTarget.classList.add('disabled')
    }
  }

  openEditModal(e) {
    let target = e.target
    while (target.classList.contains('edit-demand') === false) {
      target = target.parentNode
    }
    const url = target.dataset.unavailableurl
    this.editModalTarget.classList.remove('hidden')
    this.datesDisplayTarget.classList.add('hidden')
    initFlatpickr(url)
  }

  closeEditModal(e) {
    this.editModalTarget.classList.add('hidden')
    this.datesDisplayTarget.classList.remove('hidden')
  }

  modifyBooking(e) {
    console.log(
      document.getElementById(
        e.target.id.replace('button', 'new-range-start_date')
      ).value
    )
    console.log(
      document.getElementById(
        e.target.id.replace('button', 'new-range-end_date')
      ).value
    )
    console.log(document.getElementById('booking-id').innerText)
  }

  toggleArchiveButton(e) {
    const checkboxes = this.archiveAllCheckboxTargets
    let atLeastOneChecked = false
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        atLeastOneChecked = true
      }
    })
    this.archiveAllButtonTarget.classList.toggle('disabled', !atLeastOneChecked)
  }
}
