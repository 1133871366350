import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  connect() {
    console.log('test')
  }

  toggle() {
    console.log('toggle')
    this.contentTargets.map((target) =>
      target.classList.toggle(this.data.get('class'))
    )
  }
}
