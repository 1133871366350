import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "previewZone", "uploadButton", "submitButton"]

  display(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.previewZoneTarget.innerHTML = `<img class="${this.data.get("class")}" src=${e.target.result} />`

        if (this.data.get("modal") !== "false") {
          this.modalTarget.classList.remove("hidden")
        };
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  upload() {
    this.uploadButtonTarget.click();
  }

  closeModal() {
    this.modalTarget.classList.add("hidden")
  }

  submit() {
    this.submitButtonTarget.click()
  }
}
