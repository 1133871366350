import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["mediumDescription", "mediumLink"]

  upload() {
    const url = `/admin/${this.data.get("resource-type")}/${this.data.get("resource-id")}/links?link=${this.mediumLinkTarget.value}&description=${this.mediumDescriptionTarget.value}`
    
    Rails.ajax({
      type: "GET",
      url,
      dataType: "json",
      success: ({result}) => {
        document.querySelector("ul.links").insertAdjacentHTML('beforeend', `<li id="${result.id}"><div class="title">${this.mediumDescriptionTarget.value}</div><div class="actions"><span class="show"><a href="${this.mediumLinkTarget.value}" target="_blank">Consulter</a></span><span class="trash" data-action="click->admin-assets#deleteLink">Supprimer</span></div></li>`)
        this.mediumLinkTarget.value = ""
        this.mediumDescriptionTarget.value = ""
    }})
  }
}
