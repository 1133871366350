import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["container"]

  delete() {
    const endpoints = {
      doc: "delete-doc",
      image: "delete-attachement",
      link: "delete-link",
      video: "delete-video"
    }

    Rails.ajax({
      type: "GET",
      url: `/admin/items/${endpoints[this.data.get("type")]}/${this.data.get("id")}`,
      dataType: "json",
      success: ({result}) => {
        this.containerTarget.classList.add("hidden")
      },
    });
  }
}

