import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["passwordValidators", "smallCharValidator", "capCharValidator", "specialCharValidator", "numberValidator", "sixCharsValidator", "nonMatchingPasswords"];

  validatePassword (e) {
    const value = e.target.value    
    const smallCharRegex = /[a-z]/
    const capCharRegex = /[A-Z]/
    const specialCharRegex = /[\$\&\+\,\:\;\=\?\@\#\|\'\<\>\.\^\*\(\)\%\!\-\{\}\[\]]/
    const numberRegex = /[0-9]/
    
    if (this.passwordValidatorsTarget.classList.contains("hidden")) {
      this.passwordValidatorsTarget.classList.remove("hidden")
    }

    if (smallCharRegex.test(value)) {
      this.smallCharValidatorTarget.classList.add("validation-passed")
      this.smallCharValidatorTarget.classList.remove("validation-failed")
    } else {
      this.smallCharValidatorTarget.classList.remove("validation-passed")
      this.smallCharValidatorTarget.classList.add("validation-failed")
    }

    if (capCharRegex.test(value)) {
      this.capCharValidatorTarget.classList.add("validation-passed")
      this.capCharValidatorTarget.classList.remove("validation-failed")
    } else {
      this.capCharValidatorTarget.classList.remove("validation-passed")
      this.capCharValidatorTarget.classList.add("validation-failed")
    }

    if (specialCharRegex.test(value)) {
      this.specialCharValidatorTarget.classList.add("validation-passed")
      this.specialCharValidatorTarget.classList.remove("validation-failed")
    } else {
      this.specialCharValidatorTarget.classList.remove("validation-passed")
      this.specialCharValidatorTarget.classList.add("validation-failed")
    }
    
    if (numberRegex.test(value)) {
      this.numberValidatorTarget.classList.add("validation-passed")
      this.numberValidatorTarget.classList.remove("validation-failed")
    } else {
      this.numberValidatorTarget.classList.remove("validation-passed")
      this.numberValidatorTarget.classList.add("validation-failed")
    }

    if (value.length >= 6) {
      this.sixCharsValidatorTarget.classList.add("validation-passed")
      this.sixCharsValidatorTarget.classList.remove("validation-failed")
    } else {
      this.sixCharsValidatorTarget.classList.remove("validation-passed")
      this.sixCharsValidatorTarget.classList.add("validation-failed")
    }
  }

  savePassword(event) {
    this.data.set("password", event.target.value)
  }

  checkMatchingPassword(event) {
    if (this.data.get("password") === event.target.value) {
      this.nonMatchingPasswordsTarget.classList.add("hidden")
    } else {
      this.nonMatchingPasswordsTarget.classList.remove("hidden")
    }
  }
}
