import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const anchor = window.location.href.split("#")[1]

    if (anchor === undefined) {
      document.querySelector(".presentation").classList.add("active-anchor")
    } else {
      document.querySelector(`.${anchor}`).classList.add("active-anchor")
    }
  }
}
