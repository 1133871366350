import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hideContent(event) {
    let target = event.target

    while (target.classList.contains("resource-card") === false) {
      target = target.parentNode
    }
    document.getElementById(target.id.replace("card", "content")).classList.add("hidden")
  }
  showContent(event) {
    let target = event.target

    while (target.classList.contains("resource-card") === false) {
      target = target.parentNode
    }
    document.getElementById(target.id.replace("card", "content")).classList.remove("hidden")
  }

  showTooltip(event) {
    document.getElementById(event.target.id.replace("icon", "tooltip")).classList.remove("hidden")
  }

  hideTooltip(event) {
    document.getElementById(event.target.id.replace("icon", "tooltip")).classList.add("hidden")
  }
}
