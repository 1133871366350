import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["main"];
  showMenu(event) {
    let id = event.target.id;
    if (id === undefined || id === "" || id === null) {
      id = event.target.parentNode.id;
    }

    document.querySelectorAll(".li").forEach((el) => {
      el.classList.remove("selected");
    });
    document.querySelectorAll(".menu-content").forEach((el) => {
      el.classList.add("hidden");
    });
    document
      .getElementById(id.replace("button", "menu"))
      .classList.toggle("hidden");
  }
}
