import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["editContent", "displayContent", "buttons", "linkId", "linkDescription", "linkLink"]

  showEdit() {
    this.editContentTarget.classList.remove('hidden');
    this.displayContentTarget.classList.add('hidden');
    this.buttonsTarget.classList.add('hidden');
  }

  saveEdit() {
    Rails.ajax({
      type: "GET",
      url: `/admin/links/update?id=${this.linkIdTarget.value}&description=${this.linkDescriptionTarget.value}&link=${this.linkLinkTarget.value}`,
      dataType: "json",
      success: ({response, description}) => {
        if (response == "success") {
          this.editContentTarget.classList.add('hidden');
          this.displayContentTarget.innerText = description;
          this.displayContentTarget.classList.remove('hidden');
          this.buttonsTarget.classList.remove('hidden');
        }
      },
    });
  }
}

