import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slug"];

  fillSlug(event) {
    let value = event.target.value;
    const regex = /[\s'"`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    value = value.toLowerCase().replace(regex, "-");
    this.slugTarget.value = value;
  }
}
