import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["button", "entries", "pagination"]

  showMore() {
    let next_page = this.paginationTarget.querySelector('a[rel="next"]')
    if (next_page === null) {
      return
    }
    let url = next_page.href

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML("beforeend", data.entries)
        this.paginationTarget.innerHTML = data.pagination
        let next_next_page = this.paginationTarget.querySelector('a[rel="next"]')
        if (next_next_page === null) {
          this.buttonTarget.classList.add("hidden")
        }
      },
    });
  }
  showFilters() {
    document.querySelector(".front-filters-block").classList.toggle("hidden")
    document.querySelector(".fa-plus").classList.toggle("hidden")
    document.querySelector(".fa-minus").classList.toggle("hidden")
  }
}
