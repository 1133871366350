import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  newCategory(event) {
    console.log(this.contentTarget.value)
    this.contentTarget.value = event.target.value;
    console.log(this.contentTarget.value)
  }
}
