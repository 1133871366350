import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'filename',
    'slug',
    'uploadBtn',
    'uploadPicturesBtn',
    'uploadExamplesBtn',
    'submitButton',
    'disabledButton',
  ]

  connect() {
    if (
      document.getElementById('controller-tag-list') !== null &&
      document.getElementById('controller-tag-list') !== undefined &&
      document.getElementById('controller-tag-list').value.length > 0
    ) {
      const selectedTags = document.getElementById(
        'tag_list_from_controller'
      ).innerText
      this.data.set('selectedTags', selectedTags)
      document.getElementById('selected-tags').innerHTML = selectedTags
        .split(',')
        .map(
          (r, i) =>
            `<li key="tag-${i}"><i class="fas fa-times-circle" data-action="click->forms#deleteTag"></i><span class="tag-span">${r}</span></li>`
        )
        .join('')
    }
  }

  enableButton(event) {
    if (event.target.value !== '') {
      this.disabledButtonTarget.disabled = false
    }
  }

  displayFileName(event) {
    const value = event.target.value.split('\\')
    const name = value[value.length - 1]
    this.filenameTarget.innerText = name
    this.filenameTarget.classList.add('uploaded')
  }

  displayDocFileName(event) {
    const value = event.target.value.split('\\')
    const name = value[value.length - 1]
    document.getElementById(event.target.id.replace('btn', 'label')).innerText =
      name
    document
      .getElementById(event.target.id.replace('btn', 'label'))
      .classList.add('uploaded')
  }

  displayFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.onload = (e) => {
        document
          .getElementById('thumbnail-preview')
          .insertAdjacentHTML(
            'beforeend',
            `<img class="thumbnail-preview" src=${e.target.result} />`
          )
      }

      reader.readAsDataURL(event.target.files[0])
    }
  }
  replaceFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        if (document.getElementById('thumbnail-preview').firstChild) {
          document.getElementById('thumbnail-preview').firstChild.remove()
        }
        document
          .getElementById('thumbnail-preview')
          .insertAdjacentHTML('beforeend', `<img src=${e.target.result} />`)
      }

      reader.readAsDataURL(event.target.files[0])
    }
  }

  displayPictures(event) {
    Array.from(event.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        document
          .getElementById('pictures-preview')
          .insertAdjacentHTML(
            'beforeend',
            `<li><img src=${e.target.result} /></li>`
          )
      }

      reader.readAsDataURL(file)
    })
  }

  displayExamples(event) {
    Array.from(event.target.files).forEach((file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        document
          .getElementById('examples-preview')
          .insertAdjacentHTML(
            'beforeend',
            `<li><img src=${e.target.result} /></li>`
          )
      }

      reader.readAsDataURL(file)
    })
  }

  fillSlug(event) {
    let value = event.target.value
    const regex = /[\s'"`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
    const aRegex = /[àâä]/g
    const eRegex = /[éèêë]/g
    const iRegex = /[îï]/g
    const oRegex = /[ôö]/g
    const uRegex = /[ùûü]/g
    value = value.toLowerCase().replace(regex, '-')
    value = value.replace(aRegex, 'a')
    value = value.replace(eRegex, 'e')
    value = value.replace(iRegex, 'i')
    value = value.replace(oRegex, 'o')
    value = value.replace(uRegex, 'u')
    this.slugTarget.value = value
  }

  uploadFile() {
    this.uploadBtnTarget.click()
  }

  uploadDoc(event) {
    document.getElementById(event.target.id.replace('label', 'btn')).click()
  }
  uploadPictures() {
    this.uploadPicturesBtnTarget.click()
  }
  uploadExamples() {
    this.uploadExamplesBtnTarget.click()
  }

  addLink(event) {
    document
      .getElementById(event.target.id.replace('button', 'item'))
      .classList.remove('hidden')
    event.target.classList.add('hidden')
  }

  deleteImage(event) {
    event.target.parentNode.remove()
    Rails.ajax({
      type: 'GET',
      url: `/admin/items/delete-attachement/${event.target.parentNode.id}`,
      dataType: 'json',
      success: ({ result }) => {
        console.log('data ->', result)
      },
    })
  }

  searchTags(event) {
    const term = event.target.value.toLowerCase()

    if (term !== '' && event.code !== 'Space') {
      Rails.ajax({
        type: 'GET',
        url: `/admin/tags/search?term=${term}`,
        dataType: 'json',
        success: ({ result }) => {
          let selectedTags = this.data.get('selectedTags')

          if (selectedTags !== null && selectedTags.length > 0) {
            selectedTags = selectedTags.split(',')
            result = result.filter((r) => !selectedTags.includes(r))
          }

          const resultHtml = result
            .map(
              (r, i) =>
                `<li key="tag-${i}" data-action="click->forms#addTagFromList">${r}</li>`
            )
            .join('')
          const addTagButton = `<li id="add-tag" data-action="click->forms#addTag">Ajouter "${term}"</li>`
          document.getElementById('tags-list').innerHTML =
            resultHtml + addTagButton
          document.getElementById('tags-list').classList.remove('hidden')
        },
      })
    }
  }

  displayTag(tag) {
    let selectedTags = this.data.get('selectedTags')

    if (selectedTags == null || selectedTags.length == 0) {
      selectedTags = [tag]
    } else {
      selectedTags = selectedTags.split(',')
      selectedTags.push(tag)
    }
    selectedTags = selectedTags.join(',')
    this.data.set('selectedTags', selectedTags)
    document
      .getElementById('selected-tags')
      .insertAdjacentHTML(
        'beforeend',
        `<li><i class="fas fa-times-circle" data-action="click->forms#deleteTag"></i><span class="tag-span">${tag}</span></li>`
      )
    document.getElementById('controller-tag-list').value = selectedTags
    document.getElementById('tags-list').classList.add('hidden')
    document.getElementById('tag-input').value = ''
  }

  addTag(e) {
    const tag = document.getElementById('tag-input').value.toLowerCase()

    this.displayTag(tag)
    e.target.classList.add('hidden')
  }

  addTagFromList(e) {
    const tag = e.target.innerText.toLowerCase()
    this.displayTag(tag)
  }

  deleteTag(e) {
    const tag = e.target.parentNode.querySelector('.tag-span').innerText

    let selectedTags = this.data.get('selectedTags').split(',')

    selectedTags = selectedTags
      .filter((t) => t.trimStart() !== tag.trimStart())
      .join(',')
    this.data.set('selectedTags', selectedTags)
    document.getElementById('controller-tag-list').value = selectedTags
    e.target.parentNode.remove()
  }

  showAddCityBlock() {
    document.getElementById('add-city').classList.remove('hidden')
    document.getElementById('cities-list').classList.add('hidden')
  }

  showAddOrganizationBlock() {
    document.getElementById('add-organization').classList.remove('hidden')
    document.getElementById('organizations-list').classList.add('hidden')
  }

  searchCity(event) {
    const term = event.target.value.toLowerCase()

    if (event.code !== 'Space') {
      Rails.ajax({
        type: 'GET',
        url: `/cities/search?term=${term}`,
        dataType: 'json',
        success: ({ result }) => {
          console.log(result)
          if (result.length === 0) {
            this.showAddCityBlock()
            return
          }
          document.getElementById('cities-list').innerHTML = result
            .map(
              (r) =>
                `<li data-action="click->forms#selectOrganizationsBasedOnCity">${r}</li>`
            )
            .join('')
          document.getElementById('cities-list').classList.remove('hidden')
          document.getElementById('add-city').classList.add('hidden')
        },
      })
    }
  }

  selectOrganizationsBasedOnCity(event) {
    const term = event.target.innerText.toLowerCase()

    if (event.code !== 'Space') {
      Rails.ajax({
        type: 'GET',
        url: `/organizations/search?term=${term}`,
        dataType: 'json',
        success: ({ result }) => {
          this.baseResultOrganizations = result

          document.getElementById('organizations-list').innerHTML = result
            .map(
              (r) =>
                `<li id="organization-${
                  r[0]
                }" data-action="click->forms#addOrganizationFromList">${r[1]} ${
                  r[2] && r[2] !== 'null' && r[2].length > 0 ? `(${r[2]})` : ''
                }</li>`
            )
            .join('')
          document.getElementById('cities-list').classList.add('hidden')
          document
            .getElementById('organization-fake-input')
            .classList.remove('disabled')
          document.getElementById('city-fake-input').value =
            event.target.innerText
        },
      })
    }
  }

  openOrganizationsList(event) {
    if (!event.target.classList.contains('disabled')) {
      document.getElementById('organizations-list').classList.remove('hidden')
    }
  }

  searchOrganization(event) {
    const term = event.target.value.toLowerCase()

    if (event.code !== 'Space') {
      const result = this.baseResultOrganizations.filter((organization) =>
        organization[1].toLowerCase().includes(term)
      )

      document.getElementById('organizations-list').innerHTML = result
        .map(
          (r) =>
            `<li id="organization-${
              r[0]
            }" data-action="click->forms#addOrganizationFromList">${r[1]} ${
              r[2] && r[2] !== 'null' && r[2].length > 0 ? `(${r[2]})` : ''
            }</li>`
        )
        .join('')
    }
    if (!event.target.classList.contains('disabled')) {
      document.getElementById('organizations-list').classList.remove('hidden')
    }
  }

  addOrganization() {
    Rails.ajax({
      type: 'POST',
      url: `/etablissements?name=${
        document.getElementById('organization-fake-input').value
      }`,
      dataType: 'json',
      success: ({ result }) => {
        document.getElementById('user_organization_id').value = result.id
        document.getElementById('organizations-list').classList.add('hidden')
        document.getElementById('add-organization').classList.add('hidden')
      },
    })
  }

  addOrganizationFromList(e) {
    document.getElementById('user_organization_id').value =
      e.target.id.split('-')[1]
    document.getElementById('organization-fake-input').value =
      e.target.innerHTML
    document.getElementById('organizations-list').classList.add('hidden')
  }

  submitForm() {
    this.submitButtonTarget.click()
  }
}
