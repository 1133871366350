import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleContent(event) {
    let target = event.target

    while (target.classList.contains("section-title-container") === false) {
      target = target.parentNode
    }
    document.getElementById(target.id.replace("title", "stats")).classList.toggle("hidden")
    target.querySelector(".fa-chevron-down").classList.toggle("hidden")
    target.querySelector(".fa-chevron-up").classList.toggle("hidden")
  }
}
