import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["assetsMenu", "uploadGalleryBtn", "uploadExampleBtn", "uploadDocBtn"]

  toggleAssetsMenu() {
    this.assetsMenuTarget.classList.toggle("hidden")
  }

  deleteImage(event) {
    if (event.target.parentNode.id !== null && event.target.parentNode.id !== undefined && event.target.parentNode.id.length > 0) {
      Rails.ajax({
        type: "GET",
        url: `/admin/items/delete-attachement/${event.target.parentNode.id}`,
        dataType: "json",
        success: ({result}) => {
          console.log("data ->", result)
        },
      });
    }
  }

  deleteDoc(event) {
    const resourceType = document.getElementById('assets-menu').dataset.type
    const resourceId = document.getElementById('assets-menu').dataset.id

    event.target.parentNode.remove()
    if (event.target.parentNode.id !== null && event.target.parentNode.id !== undefined && event.target.parentNode.id.length > 0) {
      Rails.ajax({
        type: "GET",
        url: `/admin/${resourceType}/delete-doc/${event.target.parentNode.id}?resource_id=${resourceId}`,
        dataType: "json",
        success: ({result}) => {
          console.log("data ->", result)
        },
      });
    }
  }

  deleteLink(event) {
    const resourceType = document.getElementById('assets-menu').dataset.type
    const resourceId = document.getElementById('assets-menu').dataset.id

    event.target.parentNode.remove()
    if (event.target.parentNode.id !== null && event.target.parentNode.id !== undefined && event.target.parentNode.id.length > 0) {
      Rails.ajax({
        type: "GET",
        url: `/admin/${resourceType}/delete-link/${event.target.parentNode.id}?resource_id=${resourceId}`,
        dataType: "json",
        success: ({result}) => {
          console.log("data ->", result)
        },
      });
    }
  }

  deleteVideo(e) {
    Rails.ajax({
      type: "GET",
      url: `/admin/items/delete-video/${e.target.parentNode.id}`,
      dataType: "json",
      success: () => {
        document.getElementById(`video-${e.target.parentNode.id}`).innerHTML = ""
      },
    });
  }

  addLink() {
    const link = document.getElementById("link_link").value
    const description = document.getElementById("link_description").value
    const resourceType = document.getElementById('assets-menu').dataset.type
    const resourceId = document.getElementById('assets-menu').dataset.id

    Rails.ajax({
      type: "GET",
      url: `/admin/${resourceType}/${resourceId}/links?link=${link}&description=${description}`,
      dataType: "json",
      success: ({result}) => {
        document.querySelector("ul.links").insertAdjacentHTML('beforeend', `<li id="${result.id}" class="link-li"><a href="${link}" target="_blank">${description}</a><i class="fas fa-trash" data-action="click->admin-assets#deleteLink"></i></li>`)
        document.getElementById("link-add-button").classList.remove("hidden")
        document.getElementById("link-form").classList.add("hidden")
        document.getElementById("link_description").value = ""
        document.getElementById("link_link").value = ""
      }
    })
  }

  addVideo() {
    const video = document.getElementById("video_id").value
    const title = document.getElementById("video_title").value
    const description = document.getElementById("video_description").value
    const itemId = document.getElementById("video_item_id").value

    Rails.ajax({
      type: "GET",
      url: `/admin/items/${itemId}/video?video=${video}&title=${title}&description=${description}`,
      dataType: "json",
      success: ({result}) => {
        let iframe = ''

        if (result.video.includes('dai.ly')) {
          iframe = `<iframe width="100%" height="240" frameborder="0" type="text/html" src="https://www.dailymotion.com/embed/video/${result.video.split('/')[result.video.split('/').length - 1]}" allowfullscreen></iframe>`
        } else if (result.video.includes('youtu.be')) {
          iframe = `<iframe width="100%" height="240" src="https://www.youtube.com/embed/${result.video.split("/")[result.video.split("/").length - 1]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        } else {
          iframe = `<iframe width="100%" height="240" src="https://player.vimeo.com/video/${result.video.split("/")[result.video.split("/").length - 1]}"  frameborder="0" allowfullscreen></iframe>`
        }
        document.getElementById("video-preview").insertAdjacentHTML("afterbegin", iframe)
        document.getElementById("video-add-button").classList.remove("hidden")
        document.getElementById("video-form").classList.add("hidden")
        document.getElementById("video_id").value = ""
        document.getElementById("video_title").value = ""
        document.getElementById("video_description").value =""
      }
    })
  }

  uploadGallery() {
    this.uploadGalleryBtnTarget.click();
  }

  uploadExample() {
    this.uploadExampleBtnTarget.click();
  }

  uploadDoc(event) {
    this.uploadDocBtnTarget.click();
  }

  displayFile(event) {
    let id = event.target.parentNode.id;
    if (id === undefined || id === "" || id === null) {
      id = event.target.parentNode.id;
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.onload = (e) => {
       document.getElementById(id.replace("image-input", "block")).insertAdjacentHTML('afterbegin', `<li class="mt-1"><i class="fas fa-trash-alt" data-action="click->admin-assets#deleteImage"></i><img src=${e.target.result} /></li>`)
      }

      reader.readAsDataURL(event.target.files[0]);
      document.getElementById(id).classList.add("hidden")
      document.getElementById(id.replace("input", "submit")).classList.remove("hidden")
    }
  }

  displayDoc(event) {
    let id = event.target.parentNode.id;
    if (id === undefined || id === "" || id === null) {
      id = event.target.parentNode.id;
    }

    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.onload = (e) => {
       document.getElementById(id.replace("image-input", "block")).insertAdjacentHTML('afterbegin', `<li class="doc-li"><i class="fas fa-trash-alt" data-action="click->admin-assets#deleteDoc"></i><span>${event.target.files[0].name}</span></li>`)
      }

      reader.readAsDataURL(event.target.files[0]);
      document.getElementById(id).classList.add("hidden")
      document.getElementById(id.replace("input", "submit")).classList.remove("hidden")
    }
  }

  showImgBlock(event) {
    let target = event.target;
    while (target.classList.contains("menu-trigger") === false) {
      target = target.parentNode
    }


    if (document.getElementById(target.id.replace("trigger", "block")).classList.contains("hidden")) {
      document.querySelectorAll(".images-block").forEach((tag) => {
        tag.classList.add("hidden")
      })
      document.querySelectorAll(".edit-assets-block").forEach((tag) => {
        tag.querySelector(".images-block").classList.add("hidden")
        tag.querySelector(".fa-chevron-up").classList.add("hidden")
        tag.querySelector(".fa-chevron-down").classList.remove("hidden")
      })

      document.getElementById(target.id.replace("trigger", "chevron-down")).classList.add("hidden")
      document.getElementById(target.id.replace("trigger", "chevron-up")).classList.remove("hidden")
      document.getElementById(target.id.replace("trigger", "block")).classList.remove("hidden")
    } else {
      document.getElementById(target.id.replace("trigger", "chevron-down")).classList.remove("hidden")
      document.getElementById(target.id.replace("trigger", "chevron-up")).classList.add("hidden")
      document.getElementById(target.id.replace("trigger", "block")).classList.add("hidden")
    }
  }

  showLinkForm() {
    document.getElementById("link-add-button").classList.add("hidden")
    document.getElementById("link-form").classList.remove("hidden")
  }

  showVideoForm() {
    document.getElementById("video-add-button").classList.add("hidden")
    document.getElementById("video-form").classList.remove("hidden")
  }
}
