import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs"

export default class extends Controller {
  saveStep(event) {
    let target = event.target;
    while (target.classList.contains("step-form") === false) {
      target = target.parentNode
    }

    const tutorial = document.getElementById('tutorial-id').innerText

    let newStep = {}
    let url = ''

    if (target.id.includes("new-stepform")) {
      newStep.title = document.getElementById(target.id.replace('stepform', 'title')).value
      newStep.content = document.getElementById(target.id.replace('stepform', 'content')).value
      url = `/tutorials/${tutorial}/create-step?title=${newStep.title}&content=${newStep.content}`
    } else {
      newStep.title = document.getElementById(`title-${target.id}`).value
      newStep.content = document.getElementById(`content-${target.id}`).value
      url = `/steps/${target.id}/update-step?title=${newStep.title}&content=${newStep.content}`
    }

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: ({response}) => {
        const messageDiv = document.getElementById('response-message')
        if (response === "success") {
          messageDiv.innerHTML = "<div class=\"color-green border-green\">L'étape a bien été enregistrée</div>"
        } else {
          messageDiv.innerHTML = "<div class=\"color-red border-red\">Erreur dans l'enregistrement de l'étape</div>"
        }
      }
    })
  }

  replaceFile(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        document.getElementById(event.target.id.replace('picture', 'thumbnail-preview')).firstChild.remove()
        document.getElementById(event.target.id.replace('picture', 'thumbnail-preview')).insertAdjacentHTML('beforeend', `<img src=${e.target.result} />`)
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  onDragEnter(event) {
    event.target.classList.add("dragged-over")
    this.data.set('dropzone', event.target.id)
  }

  onDragLeave(event) {
    event.target.classList.remove("dragged-over")
  }

  onDrop(event) {
  }

  onDragEnd(event) {
    const stepForm = event.target
    const stepFormDropzone = event.target.previousElementSibling

    const targetDropzone = document.getElementById(this.data.get('dropzone'))
    targetDropzone.insertAdjacentElement("beforebegin", stepFormDropzone)
    targetDropzone.insertAdjacentElement("beforebegin", stepForm)

    document.querySelectorAll('.step-form').forEach((step, index) => {
      Rails.ajax({
        type: "GET",
        url: `/steps/${step.id}/update-step?order=${index + 1}`,
        dataType: "json",
        success: ({response}) => {
          const messageDiv = document.getElementById('response-message')
          if (response === "success") {
            messageDiv.innerHTML = "<div class=\"color-green border-green\">Les étapes ont bien été réagencées</div>"
          } else {
            messageDiv.innerHTML = "<div class=\"color-red border-red\">Erreur dans le réagencement de l'étape</div>"
          }
        }
      })
    })
  }

  showForm(event) {
    let target = event.target;
    while (target.classList.contains("step-form") === false) {
      target = target.parentNode
    }

    target.querySelector('.fa-chevron-down').classList.toggle('hidden')
    target.querySelector('.fa-chevron-up').classList.toggle('hidden')
    target.querySelector('.form-details').classList.toggle('hidden')
  }

  deleteImage(event) {
    let target = event.target;
    while (target.classList.contains("step-form") === false) {
      target = target.parentNode
    }

    Rails.ajax({
      type: "GET",
      url: `/steps/${target.id}/delete-image`,
      dataType: "json",
      success: ({response}) => {
        const messageDiv = document.getElementById('response-message')
        if (response === "success") {
          messageDiv.innerHTML = "<div class=\"color-green border-green\">L'image a bien été supprimée</div>"
          target.querySelector('.form-preview').innerHTML = ""
        } else {
          messageDiv.innerHTML = "<div class=\"color-red border-red\">Erreur dans la suppression de l'image</div>"
        }
      }
    })
  }

  displayNewStep(event) {
    const stepForms = document.querySelectorAll('.step-form')
    const stepFormsCount = stepForms.length

    let newDropzoneId = 0
    if (stepFormsCount > 0) {
      newDropzoneId = Number.parseInt(document.querySelectorAll('.dropzone')[document.querySelectorAll('.dropzone').length -1].id.replace("dropzone-", "")) + 1
    } else {
      newDropzoneId = 2
    }
    
    document.querySelector(".form-block").insertAdjacentHTML(
      "beforeend",
      `<li class="dropzone" id="dropzone-${newDropzoneId}" data-action="dragenter->steps-form#onDragEnter dragleave->steps-form#onDragLeave drop->steps-form#onDrop"></li>
      <li class="step-form new-step-form" id="new-stepform-${newDropzoneId}" draggable="true" data-action="dragend->steps-form#onDragEnd">
        <div class="form-drag"><i class="fas fa-grip-vertical"></i></div>
        <div class="form-content">
          <div class="form-main">
            <div class="title">Nouvelle étape</div>
            <div class="chevron" data-action="click->steps-form#showForm">
              <i class="fas fa-chevron-down hidden"></i>
              <i class="fas fa-chevron-up"></i>
            </div>
          </div>
          <div class="form-details">
            <div class="form-field full-field">
              <label>Titre *</label>
              <input type="text" id="new-title-${newDropzoneId}"></input>
            </div>
            <div class="form-field full-field">
              <label>Contenu *</label>
              <trix-editor type="text" id="new-content-${newDropzoneId}"></trix-editor>
            </div>
            <div class="form-buttons">
              <button type="button" data-action="click->steps-form#saveStep">Enregistrer</button
            </div>
          </div>
        </div>
      </li>`
    )
  }
}
